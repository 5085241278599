import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Chip } from '@ui';
import SectionTitle from './SectionTitle';
import { Project } from 'lib/Models';
import { OrderStatusChip } from '@roofsnap';
import { DateTime } from 'luxon';
import {
    OrderTypes,
    OrderTypeTitle,
    OrderTypeToString,
} from 'lib/MeasurementOrders';
import SketchOrderStatus, {
    SketchOrderTypeStatus,
} from 'lib/SketchOrderStatus';
import { useDispatch } from 'react-redux';
import { getProjectDrawingById } from 'actions/ProjectDrawingActions';
import { DIYProjectImage, ProjectImagesMapping } from 'lib/ProjectImages';
import NumberFormat from 'react-number-format';

type MeasurementSectionProps = {
    project: Project;
};

const Root = styled(Box)`
    margin-top: 3rem;
`;

const Row = styled(Box)`
    padding: 1.25rem;
    border-radius: 0.75rem;
    border: 1px solid var(--color-grey-200, #d9d9d9);
    background: #fff;
`;

const HeaderCell = styled(Typography)`
    color: var(--color-grey-400, #555);

    font-size: 0.6875rem;
    font-weight: 500;
    line-height: 1rem;
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
`;

const SubtitleTypography = styled(Typography)(() => ({
    color: '#555',
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
}));

const SubtitleNumberTypography = styled(Typography)(() => ({
    color: '#222',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    fontStyle: 'normal',
    textTransform: 'uppercase',
}));

export const SketchOrderStatusImageName: { [key: string]: string } = {
    Pending: 'PendingSketchOrder.svg',
    InProgress: 'InProgressSketchOrder.svg',
    BillingFailed: 'BillingFailedSketchOrder.svg',
    Cancelled: 'CancelledSketchOrder.svg',
};

const MeasurementSection = ({ project }: MeasurementSectionProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const projectHasOrder = Boolean(project.sketchOrder);

    let orderType = project?.sketchOrder?.sketchReportType.toLowerCase();

    let metalRoofPanelSize = project.sketchOrder!?.metalRoofPanelSize;
    let sketchOrderStatus = project.sketchOrder!?.sketchOrderStatus;

    const dispatch = useDispatch();

    const [imageUrl, setImageUrl] = useState<string>(
        `/assets/${SketchOrderStatusImageName.Pending}`
    );

    const [totalArea, setTotalArea] = useState<number>(0);
    const [eaveRuns, setEaveRuns] = useState<number | undefined>(undefined);

    const customImageConditions = [
        SketchOrderStatus.InProgress,
        SketchOrderStatus.Cancelled,
        SketchOrderStatus.BillingFailed,
        SketchOrderStatus.Pending,
    ];

    const projectImageConditions = [
        SketchOrderStatus.Billed,
        SketchOrderStatus.Complete,
        SketchOrderStatus.RevisionRequested,
        SketchOrderStatus.PendingGroupBilling,
    ];

    const getPanelSizeOrEaveRuns = () => {
        if (orderType === OrderTypes.Gutters) {
            return (
                eaveRuns !== undefined &&
                (eaveRuns > 1 ? `${eaveRuns} Runs` : `${eaveRuns} Run`)
            );
        } else if (orderType === OrderTypes.MetalRoof) {
            return `Metal Panel Size - ${metalRoofPanelSize} in.`;
        }
    };

    const getOrderStatus = () => {
        return projectHasOrder
            ? SketchOrderTypeStatus[sketchOrderStatus]
            : SketchOrderTypeStatus[SketchOrderStatus.Pending];
    };

    const getAreaOrPanelSizeOrEaveRuns = () => {
        let orderStatusType = SketchOrderTypeStatus[sketchOrderStatus];
        //the total area, panel size should only be visible for billed, complete, revision, pendinggroupbilling
        if (projectImageConditions.includes(orderStatusType)) {
            if (
                orderType === OrderTypes.MetalRoof ||
                orderType === OrderTypes.Gutters
            ) {
                return getPanelSizeOrEaveRuns();
            } else if (
                orderType === OrderTypes.FullSnap ||
                orderType === OrderTypes.HalfSnap
            ) {
                return getTotalArea();
            }
        }
    };

    const getProjectTitle = () => {
        let title = 'Draw-It-Yourself';
        if (
            orderType === OrderTypes.Gutters ||
            orderType === OrderTypes.MetalRoof
        )
            title = OrderTypeTitle[orderType];
        else if (
            orderType === OrderTypes.FullSnap ||
            orderType === OrderTypes.HalfSnap
        ) {
            title = OrderTypeToString[orderType];
        }
        return title;
    };

    const getImage = async () => {
        //show the custom image when orders are in Pending/In Progress/Cancelled/Billing Failed.
        //else show the actual image
        let img: string = `/assets/${SketchOrderStatusImageName.Pending}`;
        let orderStatusType = SketchOrderTypeStatus[sketchOrderStatus];

        if (!projectHasOrder) {
            // diy
            img = `${
                process.env.REACT_APP_BLOB_BASE_URL
            }${project.id.toLowerCase()}/${DIYProjectImage}`;
        } else if (projectImageConditions.includes(orderStatusType)) {
            //for billed, complete, revision and pending group billing
            img = `${
                process.env.REACT_APP_BLOB_BASE_URL
            }${project.id.toLowerCase()}/${ProjectImagesMapping[orderType]}`;
        } else if (customImageConditions.includes(orderStatusType)) {
            //for in progress, pending, billing failed and cancelled
            img = `/assets/${SketchOrderStatusImageName[sketchOrderStatus]}`;
        }
        setImageUrl(img);
    };

    const ProjectImage = () => {
        const onError = () =>
            setImageUrl(`/assets/${SketchOrderStatusImageName.Pending}`);
        return (
            <img
                src={imageUrl}
                onError={onError}
                width={'90px'}
                height={'70px'}
                style={{ borderRadius: '5px' }}
                alt={getOrderStatus()}
            />
        );
    };

    useEffect(() => {
        if (project.id) {
            getImage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project.id]);

    const getTotalArea = () => {
        return (
            !isNaN(totalArea) && (
                <NumberFormat
                    value={totalArea * 10}
                    decimalScale={2}
                    displayType='text'
                    suffix=' sq. ft.'
                    thousandSeparator
                    renderText={(value) => (
                        <span style={{ textTransform: 'none' }}>{value}</span>
                    )}
                />
            )
        );
    };

    const getMeasurements: any = async () => {
        const result = (await dispatch(
            getProjectDrawingById(project.id)
        )) as any;
        if (result && result.error) {
            return;
        }
        setTotalArea(result.response.measurements.totalSquares);
        setEaveRuns(result.response.gutterEaveRunsCount);
    };

    useEffect(() => {
        if (
            orderType === OrderTypes.FullSnap ||
            orderType === OrderTypes.HalfSnap ||
            orderType === OrderTypes.Gutters
        ) {
            getMeasurements();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project.id]);

    const TypeAndMeasurements = () => {
        return (
            <Stack
                flexBasis={1}
                direction={isMobile ? 'column' : 'row'}
                alignItems='center'
                gap='0.9375rem'
            >
                <ProjectImage />
                <Box>
                    <SubtitleTypography>{getProjectTitle()}</SubtitleTypography>
                    <SubtitleNumberTypography style={{ textTransform: 'none' }}>
                        {getAreaOrPanelSizeOrEaveRuns()}
                    </SubtitleNumberTypography>
                </Box>
            </Stack>
        );
    };

    const DisplayDate = () => {
        const dateTimeFormat = 'M/d/yyyy - t (ZZZZ)';
        const createdAt = DateTime.fromISO(project.createdAt)
            .toLocal()
            .toFormat(dateTimeFormat);
        const updatedAt = DateTime.fromISO(project.updatedAt)
            .toLocal()
            .toFormat(dateTimeFormat);

        return (
            <Box sx={{ display: 'flex', flexBasis: 1 }}>
                {!projectHasOrder && !project.hasDrawing && (
                    <Typography sx={{ color: '#555' }}>{createdAt}</Typography>
                )}
                {(projectHasOrder || project.hasDrawing) && (
                    <Typography sx={{ color: '#555' }}>{updatedAt}</Typography>
                )}
            </Box>
        );
    };

    const Status = () => {
        return (
            <Box sx={{ display: 'flex', flexBasis: 1 }}>
                {projectHasOrder && (
                    <OrderStatusChip
                        status={project.sketchOrder.sketchOrderStatus}
                    />
                )}
                {!projectHasOrder && (
                    <Chip
                        label={project.hasDrawing ? 'Saved' : 'New'}
                        variant={project.hasDrawing ? 'green' : 'orange'}
                    />
                )}
            </Box>
        );
    };

    const ActionsMenu = () => {
        const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(
            null
        );

        const menuOpen = Boolean(menuAnchor);

        const handleMenuClick = (
            event: React.MouseEvent<HTMLButtonElement>
        ) => {
            setMenuAnchor(event.currentTarget);
        };

        const handleMenuClose = () => {
            setMenuAnchor(null);
        };

        return (
            <Box
                justifyContent='flex-end'
                sx={{ display: 'flex', flexBasis: 1 }}
            >
                <IconButton onClick={handleMenuClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    open={menuOpen}
                    anchorEl={menuAnchor}
                    onClose={handleMenuClose}
                >
                    <MenuItem>Edit Diagram</MenuItem>
                    <MenuItem>Get Revision</MenuItem>
                    <MenuItem>Measurements</MenuItem>
                    <MenuItem>Generate Report</MenuItem>
                    <MenuItem>View Report</MenuItem>
                    <MenuItem>View 3-D (Beta)</MenuItem>
                </Menu>
            </Box>
        );
    };

    return (
        <Root>
            <Box sx={{ margin: '32px 16px 16px 2px' }}>
                <SectionTitle title='Measurements' />
            </Box>

            <Stack
                direction='row'
                padding='0 0.5rem 0.5rem 0.5rem'
                sx={{
                    display: { xs: 'none', md: 'flex' },
                    justifyContent: 'space-between',
                }}
            >
                <Box sx={{ flex: { xs: 2.5 } }}>
                    <HeaderCell>Report</HeaderCell>
                </Box>
                <Box sx={{ flex: { xs: 2.4 } }}>
                    <HeaderCell>Time</HeaderCell>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <HeaderCell>Status</HeaderCell>
                </Box>
                <Box
                    width='15%'
                    justifyContent='flex-end'
                    sx={{ display: 'flex', flex: 1 }}
                >
                    <HeaderCell>Actions</HeaderCell>
                </Box>
            </Stack>
            <Row>
                {!isMobile && (
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{ whiteSpace: 'nowrap' }}
                    >
                        <TypeAndMeasurements />
                        <DisplayDate />
                        <Status />
                        <ActionsMenu />
                    </Stack>
                )}
                {isMobile && (
                    <Stack
                        direction='column'
                        gap={5}
                        sx={{ whiteSpace: 'nowrap' }}
                    >
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='flex-start'
                        >
                            <TypeAndMeasurements />
                            <ActionsMenu />
                        </Stack>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Status />
                            <DisplayDate />
                        </Stack>
                    </Stack>
                )}
            </Row>
        </Root>
    );
};

export default MeasurementSection;

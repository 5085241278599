import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { getProjectById, updateProjectDetails } from 'actions/ProjectActions';
import { RoofSnapState, Project } from 'lib/Models';
import Estimate from './Components/Estimate';
import NotesDialog, { NotesData } from './Components/NotesDialog';
import ProjectDetails from './Components/ProjectDetails';
import MeasurementSection from './Components/MeasurementSection';
import { ProjectImagesSection } from './Components/ProjectImages';
import ProjectTitleBar from './Components/ProjectTitleBar';
import ProjectStatus from 'lib/Models/ProjectStatus';
import CreateEstimateDialog from '.../../components/CreateEstimate/CreateEstimateDialog';
import { createProjectEstimate } from 'actions/ProjectEstimate';
import LoggerWrapper from 'lib/Logger';

type ProjectParams = {
    projectId: string;
};

interface EstimateData {
    name: string;
    templateId: string;
}

export const ProjectHome: React.FC = () => {
    const { projectId } = useParams<ProjectParams>();
    const dispatch = useDispatch();
    const history = useHistory();
    const { currentProject } = useSelector(
        (store: RoofSnapState) => store.project
    );
    const [openNotesDialog, setOpenNotesDialog] = React.useState(false);
    const [openCreateEstimateDialog, setOpenCreateEstimateDialog] =
        React.useState(false);
    const handleNotesClick = () => {
        setOpenNotesDialog(true);
    };
    const handleSaveNotes = (notes: NotesData) => {
        let updatedProject = { ...currentProject, ...notes };
        dispatch(updateProjectDetails(updatedProject));
    };
    const handleStatusChange = (status: keyof ProjectStatus) => {
        let updatedProject = { ...currentProject, projectStatus: status };
        dispatch(updateProjectDetails(updatedProject));
    };
    const handleTryEstimate = () => {
        setOpenCreateEstimateDialog(true);
    };
    const handleCreateEstimateClicked = async (estimateData: EstimateData) => {
        setOpenCreateEstimateDialog(false);
        try {
            let result: any = await dispatch(
                createProjectEstimate(currentProject.id, estimateData)
            );
            if (result.response.id) {
                history.push(
                    `/projects/${currentProject.id}/estimates/${result.response.id}`
                );
            }
        } catch (error) {
            LoggerWrapper.log(error);
        }
    };
    const handleCancelCreatingEstimate = () => {
        setOpenCreateEstimateDialog(false);
    };
    const isEmptyProject = (project: Project) => {
        return Object.keys(project).length === 0;
    };
    React.useEffect(() => {
        if (!currentProject || isEmptyProject(currentProject)) {
            dispatch(getProjectById(projectId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);
    return (
        <>
            <Grid container justifyContent='center'>
                <Grid item xs={12} sm={10} lg={8}>
                    {!isEmptyProject(currentProject) && (
                        <>
                            <Box mt={2}>
                                <ProjectTitleBar
                                    title={currentProject.projectName}
                                    status={currentProject.projectStatus}
                                    onStatusChange={(status) => {
                                        handleStatusChange(
                                            status as keyof ProjectStatus
                                        );
                                    }}
                                ></ProjectTitleBar>
                            </Box>
                            <Box marginTop={'18px'}>
                                <ProjectDetails
                                    project={currentProject}
                                    addNotesClicked={handleNotesClick}
                                ></ProjectDetails>
                            </Box>
                            <MeasurementSection project={currentProject} />
                            <Box mt='30px'>
                                <Estimate onTryEstimate={handleTryEstimate} />
                            </Box>
                            <ProjectImagesSection projectId={projectId} />
                        </>
                    )}
                </Grid>
            </Grid>
            <NotesDialog
                open={openNotesDialog}
                setOpen={setOpenNotesDialog}
                saveNotes={handleSaveNotes}
                notes={currentProject.notes}
                notesContract={currentProject.notesContract}
                notesDelivery={currentProject.notesDelivery}
                notesInstallation={currentProject.notesInstallation}
                notesPreStart={currentProject.notesPreStart}
            ></NotesDialog>
            <CreateEstimateDialog
                open={openCreateEstimateDialog}
                onCreateClick={handleCreateEstimateClicked}
                onCancelClick={handleCancelCreatingEstimate}
            />
        </>
    );
};

export default ProjectHome;

const featureIsEnabled = (flagName: string) =>
    (process.env.REACT_APP_FEATURE_FLAGS || '').includes(flagName);

const featureFlags = {
    accountManagement: featureIsEnabled('accountManagement'),
    projectBoard: featureIsEnabled('projectBoard'),
    cloneProject: featureIsEnabled('cloneProject'),
    v2Documents: featureIsEnabled('v2Documents'),
    eSignatures: featureIsEnabled('eSignatures'),
    vexcelImagery: featureIsEnabled('vexcelImagery'),
    parcelLines: featureIsEnabled('parcelLines'),
    v2DocumentEditor: featureIsEnabled('v2DocumentEditor'),
    v2Billing: featureIsEnabled('v2Billing'),
    v2UsersPage: featureIsEnabled('v2UsersPage'),
    metalRoof: featureIsEnabled('metalRoof'),
    gutters: featureIsEnabled('gutters'),
    diyGutters: featureIsEnabled('diyGutters'),
    newOrderFlow: featureIsEnabled('newOrderFlow'),
    easierEstimates: featureIsEnabled('easierEstimates'),
};

export default featureFlags;

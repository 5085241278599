const OrganizationFeatures = {
    AddOns: 'AddOns',
    Estimating: 'Estimating',
    SketchService: 'SketchService',
    AllowSnappingGoogleImagery: 'AllowSnappingGoogleImagery',
    RushOrdering: 'RushOrdering',
    EnableMetalRoof: 'EnableMetalRoof',
    EnableGutters: 'EnableGutters',
    EnableEasierEstimates: 'EnableEasierEstimates',
};

export default OrganizationFeatures;
